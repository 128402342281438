.c-header {
  background-color: #fff;
  position: relative;
  .c-logoBox {
    position:relative;
    width: 35%;
    left: 200px;
    img {
      width: 25%;
      height: 59px;
    }
  }
  
  @media screen and (min-width: 768px) {
    position:relative;
    width: 100%;
    left: 0px;
    top: 20px;
    img {
      width: 30%;
      height: 50px;
    } 
  }
  @media screen and (min-width: 992px) {
    width: 100%;
  }
  @media screen and (min-width: 1440px) {
    position:relative;
    width: 100%;
    left: 0px;
    top: 20px;
    img {
      width: 30%;
      height: 50px;
    } 
  }
}
.c-home {
  .c-backGround {
    width: 100%;
    height: 944px;
    background-image: url(../colourbg.png);
    position: relative;
    background-position: bottom;
    background-size: cover;

    @media screen and (min-width: 768px) {
      width: 100%;
      height: 944px;
      background-image: url(../colourbg.png);
      position: relative;
      background-position: bottom;
      background-size: cover;
    }
    @media screen and (min-width: 992px) {
      width: 100%;
    }
    @media screen and (min-width: 1440px) {
      width: 100%;
      height: 944px;
      background-image: url(../colourbg.png);
      position: relative;
      background-position: bottom;
      background-size: cover;
      } 

    .c-product {
      position: absolute;
      top: 90px;
      right: 15rem;
      width: 35%;
      img {
        width: 100%;
        height: 560px;
      }
      @media screen and (min-width: 768px) {
        position: absolute;
        top: 170px;
        right: 0rem;
        width: 35%;
        img {
          width: 100%;
          height: 500px;
        } 
      }
      @media screen and (min-width: 992px) {
        width: 100%;
      }
      @media screen and (min-width: 1440px) {
        position: absolute;
        top: 220px;
        right: 11rem;
        width: 35%;
        img {
          width: 100%;
          height: 500px;
        } 
      } 
    }
    .c-text1{
      position: absolute;
      left: 200px;
      top: 160px;
      font-size: 48px;
      font-weight: 300;
      color: #fff;
      font-family: 'Nunito Sans', sans-serif;

      @media screen and (min-width: 768px) {
        position: absolute;
        left: 180px;
        top: 245px;
        font-size: 30px;
        font-weight: 300;
        color: #fff;
        font-family: 'Nunito Sans', sans-serif;
      }
      @media screen and (min-width: 992px) {
        width: 100%;
      }
      @media screen and (min-width: 1440px) {
        position: absolute;
        left: 180px;
        top: 290px;
        font-size: 40px;
        font-weight: 300;
        color: #fff;
        font-family: 'Nunito Sans', sans-serif;
      }

    }
    .c-text2{
      position: absolute;
      left: 200px;
      top: 230px;
      font-size: 48px;
      font-weight: 300;
      color: #fff;
      font-family: 'Nunito Sans', sans-serif;

      @media screen and (min-width: 768px) {
        position: absolute;
        left: 180px;
        top: 310px;
        font-size: 30px;
        font-weight: 300;
        color: #fff;
        font-family: 'Nunito Sans', sans-serif;
      }
      @media screen and (min-width: 992px) {
        width: 100%;
      }

      @media screen and (min-width: 1440px) {
        position: absolute;
        left: 180px;
        top: 340px;
        font-size: 40px;
        font-weight: 300;
        color: #fff;
        font-family: 'Nunito Sans', sans-serif;
      }
    }
    .c-text3{
      position: absolute;
      left: 200px;
      top: 350px;
      font-size: 20px;
      font-weight: 100;
      color: #fff;
      font-family: 'Nunito Sans', sans-serif;

      @media screen and (min-width: 768px) {
        position: absolute;
        left: 180px;
        top: 420px;
        font-size: 13px;
        font-weight: 300;
        color: #fff;
        font-family: 'Nunito Sans', sans-serif;
      }
      @media screen and (min-width: 992px) {
        width: 100%;
      }
      @media screen and (min-width: 1440px) {
        position: absolute;
        left: 180px;
        top: 470px;
        font-size: 16px;
        font-weight: 300;
        color: #fff;
        font-family: 'Nunito Sans', sans-serif;
      }
    }
    .c-text4{
      position: absolute;
      left: 200px;
      top: 470px;
      font-size: 20px;
      font-weight: bold;
      color: #fff;
      font-family: 'Nunito Sans', sans-serif;

      @media screen and (min-width: 768px) {
        position: absolute;
        left: 180px;
        top: 525px;
        font-size: 10px;
        font-weight: bold;
        color: #fff;
        font-family: 'Nunito Sans', sans-serif;
      }
      @media screen and (min-width: 992px) {
        width: 100%;
      }

      @media screen and (min-width: 1440px) {
        position: absolute;
        left: 180px;
        top: 595px;
        font-size: 15px;
        font-weight: bold;
        color: #fff;
        font-family: 'Nunito Sans', sans-serif;
      }
    }
    .c-line{
      position: absolute;
      left: 200px;
      width: 1500px;
      top: 825px;
      height: 0;
      border: 1px solid #fff;
      margin: 3px;
      display:inline-block;

      @media screen and (min-width: 768px) {
        position: absolute;
        left: 170px;
        width: 500px;
        top: 825px;
        height: 0;
        border: 1px solid #fff;
        margin: 3px;
        display:inline-block;
      }
      @media screen and (min-width: 992px) {
        width: 100%;
      }

      @media screen and (min-width: 1440px) {
        position: absolute;
        left: 170px;
        width: 1100px;
        top: 855px;
        height: 0;
        border: 1px solid #fff;
        margin: 3px;
        display:inline-block;
      } 
    }
    .c-logoBox2{
      position: absolute;
      top: 870px;
      left: 650px;
      width: 10%;
      img {
        width: 100%;
      }

      @media screen and (min-width: 768px) {
        position: absolute;
        top: 865px;
        left: 265px;
        width: 10%;
        img {
          width: 100%;
        }
      }
      @media screen and (min-width: 992px) {
        width: 100%;
      }
      @media screen and (min-width: 1440px) {
        position: absolute;
        top: 880px;
        left: 500px;
        width: 8%;
        img {
          width: 100%;
        }
      } 
    }
    .c-text5{
      position: absolute;
      left: 840px;
      top: 858px;
      font-size: 18px;
      font-weight: 300;
      color: #fff;
      font-family: 'Nunito Sans', sans-serif;

      @media screen and (min-width: 768px) {
        position: absolute;
        left: 350px;
        top: 858px;
        font-size: 10px;
        font-weight: bold;
        color: #fff;
        font-family: 'Nunito Sans', sans-serif;
      }
      @media screen and (min-width: 992px) {
        width: 100%;
      }
      @media screen and (min-width: 1440px) {
        position: absolute;
        left: 620px;
        top: 868px;
        font-size: 15px;
        font-weight: 300;
        color: #fff;
        font-family: 'Nunito Sans', sans-serif;
      } 
    }
  }
}