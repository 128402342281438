html, body {
    max-width: 100%;
    width: 100%;
    min-height: 100vh;
}

#root { 
    min-height: 100vh;
}


.c-home { 
    min-height: 100%;
}

.c-home .c-backGround { 
    min-height: 100%;
    padding-bottom: 70px;
}

.content_container {
    max-width: 1440px;
    margin: auto;
}

.main_content {
    display: flex;
}

.main_content .wording {
    padding: 20px;
}

.main_content .text_main p {
    margin-bottom: 0;
}

.main_content .text_main {
    font-size: 4em;
}

.main_content .text_sub {
    font-size: 1.5em;
}

@media screen and (max-width: 1440px) {
    .main_content .text_main {
        font-size: 3em;
    }
    .main_content .text_sub {
        font-size: 1.5em;
    }
    .content_container {
        max-width: 100%;
        margin: auto;
    }
    
}

@media screen and (max-width: 1023px) {
    .main_content {
        flex-direction: column;
    }
    .image_container { 
        width: 100%;
    }

    .image_container img {
        width: 100%;
    }
}

@media screen and (max-width: 960px) {
    .main_content .text_main {
        font-size: 2em;
    }
    .main_content .text_sub {
        font-size: 1.5em;
    }
}